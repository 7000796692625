<template>
    <v-hover v-slot="{ hover }">
        <div class="py-8 py-md-16 px-7 text-center cardtype" :class="type" :data-aos="$vuetify.breakpoint.mdAndUp ? item.animation : 'fade-left'">
            <img v-if="item.icon" class="my-3" :src="getImage(item.icon, hover)" alt="">
            <img v-if="item.svg" :src="getImage(item.svg, hover)" alt="">
            <p class="h4 bold text-center textcolor montserrat mt-1">{{item.title}}</p>
            <p class="h5 text-center opensans text">{{item.text}}</p>
        </div>
    </v-hover>
</template>

<script>
export default {
    name:"ServiceCard",
    props:['item', 'type', 'icon'],
    methods: {
        getImage(img, hover = false){
            if(hover && this.$vuetify.breakpoint.mdAndUp && typeof this.icon !== 'undefined')
                return require('@/assets/icon/' + img + '-' + this.icon.hovertype + '.svg');

            if(typeof this.icon !== 'undefined')
                return require('@/assets/icon/' + img + '-' + this.icon.basetype + '.svg');

            return require('@/assets/icon/' + img + '.svg');
        }
    }
}
</script>

<style lang="scss">
.cardtype{
    border: 1px solid #f5f5f5;
    box-sizing: border-box;
    border-radius: 40px;
    .text{
        color: #848484;
    }
}
.cardblue{
    background: #2763fb;
    border: 1px solid #2763fb;
    border-radius: 40px;
    transition: 0.3s;
    .textcolor{
        color: #ffffff;
    }
    .text{
        color: #a9c1fd;
    }
}
.cardwhite{
    background: #ffffff;
    border-radius: 40px;
    transition: 0.3s;
    .textcolor{
        color: #323232;
    }
}
.cardlight{
    background: #F4F7FF;
    border: 1px solid #F4F7FF;
}
@media all and (min-width: 930px){
    .cardblue:hover{
        background: #FFFFFF;
        p{
            color: #323232;
        }
    }
    .cardwhite:hover{
        background: #2763fb;
        .textcolor{
            color: #ffffff;
        }
        .text{
            color: #a9c1fd;
        }
    }
}
</style>
