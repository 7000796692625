<template>
    <div class="">
        <v-row>
            <v-col class="" v-for="(item, i) in servicescard" :key="i" cols="12" sm="6" lg="4">
                <div class="text-center h-100" :data-aos="$vuetify.breakpoint.mdAndUp ? item.aos : 'fade-left'" data-aos-delay="1000">
                    <service-card :item="item" class="h-100" :type="type" :icon="icon"/>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ServiceCard from '@/components/base/ServiceCard.vue'

export default {
    components: {
        ServiceCard
    },
    props: ['type', 'icon'],
    data: () => ({
        servicescard: [
            {
                svg: 'cardiogram',
                title: 'Healthcare',
                text: 'We aim to create the most patient-friendly software that empowers healthcare industry workers and minimizes paperwork',
                aos: 'fade-down-right'
            },
            {
                svg: 'lotus-flower',
                title: 'Beauty Services',
                text: 'We are helping beauty brands and companies to reshape the industry with the most innovative, affordable, and cost-effective technology solutions',
                aos: 'fade-down'
            },
            {
                svg: 'marketplace',
                title: 'Retail and E-commerce',
                text: 'We transform your clients’ shopping experience with an engaging mobile/web app and develop optimal software solutions for modern retail',
                aos: 'fade-down-left'
            },
            {
                svg: 'credit-card',
                title: 'FinTech',
                text: 'GBMF speeds up innovation, providing FinTech app development with scalable financial technology for FinTech start-ups, banks, and other businesses',
                aos: 'fade-right'
            },
            {
                svg: 'home',
                title: 'Construction',
                text: 'We propose advanced software solutions that take your construction business into the digital era',
            },
            {
                svg: 'manufacturer',
                title: 'Manufacturing and Production',
                text: 'We develop manufacturing solutions: data-driven decisions with analytical and reporting facilities, creating user-friendly, multilingual websites for various businesses',
                aos: 'fade-left'
            },
            {
                svg: 'plane',
                title: 'Hospitality and Travel',
                text: 'We create effective software solutions, such as mobile apps, e-booking systems, swift transactions, and universal digital identity for unrivaled customer experience',
                aos: 'fade-up-right'
            },
            {
                svg: 'location-pin',
                title: 'Transportation and Logistics',
                text: 'You can optimize your business performance and increase your revenue with our cutting-edge transportation management solutions',
                aos: 'fade-up'
            },
            {
                svg: 'basketball',
                title: 'Sports and Fitness',
                text: 'We develop unique, end-to-end sports and fitness apps for any kind of sports activities from personal training to managing sports events. GBMF will strategize, wireframe, design, build and maintain any custom sports software to deliver a flawless user experience',
                aos: 'fade-up-left'
            },
        ]
    })
}
</script>

<style lang="scss">
</style>
