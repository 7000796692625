<template>
    <div class="mt-md-16 pt-md-16 weare-main" border="bottom" dark>
        <v-row>
            <v-col class="mt-10" cols="12" md="5" lg="6">
                <!-- <affix class="" :relative-element-selector="$vuetify.breakpoint.width > 1024 ? `#weare-content` : `#his`" :offset="{ top: 0, bottom: 20 }" :scroll-affix="false"> -->
                    <div class="wearcircle" data-aos="fade-right">
						<slot name="left"></slot>
                    </div>
                    <div v-if="link" class="text-cnter mt-16 pt-16 d-none d-lg-block" data-aos="fade-right">
                        <router-link :to="{ name: link, params: {} }">
                            <button-standard-large large="true" outlined btnclass="btn-border-radius border-silver-01 removebefore py-7 px-16">Show more</button-standard-large>
                        </router-link>
                    </div>
                    <div class="d-none d-lg-block mt-16" data-aos="fade-right">
                        <img class="" :src="require('@/assets/icon/dotblue.svg')">
                    </div>
                <!-- </affix> -->
            </v-col>
            <v-col cols="12" md="7" lg="6" id="weare-content">
                <v-list flat class="pt-md-16">
                    <v-list-item class="my-md-12 my-6 mt-md-0" data-aos="fade-left" v-for="(item,i) in weare" :key="i" :ripple="false">
                        <v-list-item-content>
                            <v-list-item-title class="text-nowrap">
                                <p class="h4 bold montserrat" v-html="item.title"></p>
                                <p class="opensans h5 text-silver" v-html="item.text"></p>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { Affix } from 'vue-affix';

export default {
    name: 'WeAre',
    components: {
        Affix,
    },
    props: ['weare', 'link']
}
</script>

<style lang="scss">
.wearcircle::before{
    content: " ";
    margin-left: -95px;
    position: absolute;
    display: inline-block;
    width: 180px !important;
    height: 180px !important;
    border-radius: 180px !important;
    background-color: $blue1 !important;
}
@media all and (max-width:660px){
    .wearcircle::before{
        content: " ";
        margin-left: -95px;
        position: absolute;
        display: inline-block;
        width: 180px !important;
        height: 180px !important;
        border-radius: 180px !important;
        background-color: $blue1 !important;
    }
}
@media all and (min-width:660px){
//     .wearcircle::before{
//         content: " ";
//         margin-left: -95px;
//         position: absolute;
//         display: inline-block;
//         width: 180px !important;
//         height: 180px !important;
//         border-radius: 180px !important;
//         background-color: $blue1 !important;
//     }
    .weare-main{
        .v-list{
            width: 75%;
        }
    }
}
@media all and (min-width:930px){
//     .wearcircle::before{
//         content: " ";
//         margin-left: -95px;
//         position: absolute;
//         display: inline-block;
//         width: 180px !important;
//         height: 180px !important;
//         border-radius: 180px !important;
//         background-color: $blue1 !important;
//     }
    .weare-main{
        .v-list{
            width: 100%;
        }
    }
}

</style>
