<template>
    <v-container>
        <category-services v-for="(item, i) in catServices" :key="i" :category="item"  class="mb-lg-16"/>
    </v-container>
</template>

<script>
import CategoryServices from '@/components/base/CategoryServices.vue'

export default {
    components: {
        CategoryServices
    },
    data: () => ({
        catServices: [
            {
                title: 'Web <br/>Application',
                items: [
                    {
                        icon: 'business',
                        title: 'Business Websites',
                        text: 'GBMF delivers highly-available, functional, and visually-engaging web products and solutions for enterprises',
                    },
                    {
                        icon: 'marketplace',
                        title: 'E-commerce Marketplace',
                        text: 'GBMF e-commerce web development services will increase your business performance due to our optimal solutions',
                    },
                    {
                        icon: 'crm',
                        title: 'CRM',
                        text: 'We provide end-to-end customized CRM Integration and CRM Implementation services for efficient management processes',
                    },
                    {
                        icon: 'fintech',
                        title: 'FinTech',
                        text: 'Our software development services advance both early-stage FinTech startups and established financial organizations',
                    }
                ]
            },
            {
                title: 'Mobile <br/>Application',
                items: [
                    {
                        icon: 'android1',
                        title: 'Android',
                        text: 'We create an all-encompassing range of superb apps for one of the most popular mobile platforms',
                    },
                    {
                        icon: 'ios',
                        title: 'iOS',
                        text: 'With our astonishing digital products, we bring the best experience to your clients who use Apple devices',
                    },
                ]
            },
            {
                title: 'Software <br/>Development',
                items: [
                    {
                        icon: 'lapcog',
                        title: 'Software Audit',
                        text: 'We conduct in-depth reviews of software programs to check their quality, progress, adherence to plans, and much more',
                    },
                    {
                        icon: 'searchcog',
                        title: 'DevOps Consulting',
                        text: 'Our DevOps consultants provide the ultimate solutions for high operational efficiency and automation',
                    },
                    {
                        icon: 'supporticon',
                        title: 'Tech Stack Recommendation',
                        text: 'GBMF will help you to choose the most efficient technology solution for any project you have in mind',
                    },
                    {
                        icon: 'techcog',
                        title: 'Digital Transformation',
                        text: 'Digital transformation incorporates digital technology into all business spheres to improve all business processes',
                    }
                ]
            },
            {
                title: 'UX/UI Design',
                items: [
                    {
                        icon: 'web-design',
                        title: 'Web App Design',
                        text: 'We provide first-rate Web design services that will take your enterprise to a new level',
                    },
                    {
                        icon: 'smartphone',
                        title: 'Mobile App Design',
                        text: 'With our expertise in providing flawless UI and UX solutions, we design user-friendly and intuitive application flows',
                    },
                ]
            }
        ],
    })
}
</script>
