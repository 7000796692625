<template>
    <div>
        <div class="bg-blue">
            <div class="zi-1 rectangle-bghead d-none d-lg-block"></div>
            <v-container>
                <div class="py-10 mt-lg-16 pt-lg-10">
                    <h1 class="h1 montserrat text-center text-md-left white--text">About Us</h1>
                    <p class="h5 my-6 opensans text-center  text-md-left white--text">
                        We are a one-stop IT development company you’ll ever need, whatever your goal is.
Since we founded the company, we have been driven by the permanent desire to be better, to do better, and to create high-performance products. We are people of no boundaries when it comes to creativity, and when we work together, we can change the industry.
                    </p>
                    <div class="getstarttrans d-flex flex-column pt-3 mt-3 pt-md-0 mt-lg-13 mb-lg-16 flex-md-row">
                        <span class="d-flex justify-center mr-0 mr-md-2" @click.stop="$refs.getstarted.changeDialogget()">
                            <button-standard-large class="py-8 px-15 my-2 removebefore" large="true" btnclass="btn-border-radius bg-white">Get Started</button-standard-large>
                        </span>
                        <get-started-block  ref="getstarted"/>
                    </div>
                </div>
            </v-container>
        </div>
        <v-container>
			<we-are />
            <philosofy />
        </v-container>
        <div class="bg-blue py-16">
            <v-container class="">
                <p class="h3 montserrat bold text-center py-10 white--text">Industries</p>
                <div class="">
                    <industries type="cardblue" :icon="{basetype: 'white', hovertype: 'blue'}"/>
                </div>
            </v-container>
        </div>
        <div class="my-6">
            <v-container>
                <div class="h2 montserrat fontw-700 my-12">Services</div>
                <category-services />
            </v-container>
            <v-container>
                <div class="my-md-16 py-md-16">
                    <technologies/>
                </div>
            </v-container>
        </div>
        <quest />
    </div>
</template>

<script>
import GetStartedBlock from '@/components/base/GetStartedBlock.vue'
import ServiceCard from '@/components/base/ServiceCard.vue'
import CategoryServices from '@/components/page/services.vue'
import Technologies from '@/components/page/tehnologies.vue'

import Industries from '@/components/page/industries.vue'
import WeAre from '@/components/page/weare/whyus'
import Philosofy from '@/components/page/weare/philosofy'
import Quest from '@/components/page/quest.vue'

export default {
    metaInfo() {
        return {
            meta: [
                { name: 'description', content:  'GBMF focuses on business as well as on people. Providing excellent IT services across many industries is as equally important for us as building trust-based relationships with all of the clients.'},
                { property: 'og:title', content: "About us: find out how GBMF stands out among many great IT companies"},
                { property: 'og:site_name', content: 'GBMF'},
                { property: 'og:type', content: 'website'},
                { name: 'robots', content: 'index,follow'}
            ]
        }
    },
    components:{
        ServiceCard,
        GetStartedBlock,
        CategoryServices,
        Technologies,
        WeAre,
		Philosofy,
        Industries,
        Quest
    },
    data:() => ({

        servicescard: [
            {
                svg: 'cardiogram',
                title: 'Healthcare',
                text: 'We aim to create the most patient-friendly software that empowers healthcare industry workers and minimize paperwork.'
            },
            {
                svg: 'lotus-flower',
                title: 'Beauty Services',
                text: 'We are helping beauty brands and companies reshape the industry with the most innovative and scalable technology solutions that are affordable and cost-effective.'
            },
            {
                svg: 'marketplace',
                title: 'Retail and eCommerce',
                text: 'We do a total transformation of your client’s shopping experience with an engaging mobile/web app and develop engaging and exciting software solutions for modern retail.'
            },
            {
                svg: 'credit-card',
                title: 'FinTech',
                text: 'GBMF speeds up innovation, providing FinTech app development with scalable financial technology for FinTech start-ups, banks, and other businesses.'
            },
            {
                svg: 'home',
                title: 'Construction',
                text: 'We propose advanced software solutions that take your construction business into the digital era.'
            },
            {
                svg: 'manufacturer',
                title: 'Manufacturing and production',
                text: 'We develop manufacturing solutions: data-driven decisions with analytical and reporting facilities, creating user-friendly multilingual websites for various businesses'
            },
            {
                svg: 'plane',
                title: 'Hospitality and Travel',
                text: 'We extend the comfort of your clients by creating effective software solutions, such as mobile apps, e-booking systems, swift transactions, and universal digital identity to make the customer experience more delightful.'
            },
            {
                svg: 'location-pin',
                title: 'Transportation and Logistics',
                text: 'We will help you optimize your business performance and increase revenue by developing custom transportation management with the latest tech that gives you an edge over competition across  the board'
            },
            {
                svg: 'basketball',
                title: 'Sports and Fitness',
                text: 'We develop unique sports and fitness apps for any aspect of sports activities from personal training to managing sports events and beyond. GBMF will strategize, wireframe, design, build and maintain any custom sports software to deliver a flawless user experience'
            },
        ]
    }),
    created() {
        this.$emit('headerClass', 'headerblue');
    },
}
</script>

<style lang="scss">
.firstletterblue::first-letter{
    color: #38A4DC;
}
</style>
