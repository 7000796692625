<template>
	<we-are :weare="dat">
		<template v-slot:left>
			<p class="h2 montserrat bold relative pt-13 pl-4 pl-sm-0 pt-sm-0"><span class="white--text">C</span>ompany <br> <span class="white--text">P</span>hilosophy</p>
		</template>
	</we-are>

</template>

<script>
import WeAre from '@/components/page/weare'

export default {
    name: 'WhiUs',
    components: {
        WeAre
    },
    data: () => ({
        dat: [
			{
				title: 'Client-focus ',
				text: 'We are passionate about our clients; we want to exceed the expectations of our clients by delivering world class quality, meeting agreed delivery time lines and act professionally & responsibly at all times.'
			},
			{
				title: 'Innovation',
				text: 'We encourage personal and professional development. We thrive in a competitive landscape and demonstrate a genuine interest in keeping an open and pro-active mindset to innovation. Recognition for initiatives by individuals in our workforce is key to ensuring an atmosphere of everyone feeling valued.'
			},
			{
				title: 'Integrity',
				text: 'We treat everyone with respect and courtesy. Our culture promotes honesty and transparency. <br/>Seeking advice and an open dialogue builds strength and promotes constant initiative in our quest to keep improving.'
			}
        ],
    })
}
</script>
