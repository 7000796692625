<template>
    <we-are :weare="dat">
		<template v-slot:left>
			<p class="h2 montserrat bold relative pt-13 pl-4 pl-sm-0 pt-sm-0"><span class="white--text">W</span>e are <br> <span class="white--text">G</span>BMF</p>
		</template>
	</we-are>
</template>

<script>
import WeAre from '@/components/page/weare'

export default {
    name: 'WhiUs',
    components: {
        WeAre
    },
    data: () => ({
        dat:[
            // {
            //     title: 'Why Us',
            //     text: 'It’s a matter of success to find a company that has the right stuff. And we do. We can support your business with all modern programming languages so you have a universal toolbox for any of your tasks. <br/>We build harmonious relationships with each of our clients, making the working process smooth and efficient.',
            // },
            // {
            //     title: 'Principles and Values',
            //     text: 'We believe that collaboration is not just about business. It is about relations. <br/>The whole working process is about building trust and giving people what they need the most – empathy and great results for their businesses.',
            // },
            // {
            //     title: 'Values',
            //     text: 'We satisfy the needs of our clients, meeting and exceeding their expectations with every software development experience.<br/>We build win-win partnerships - we view our partners as allies and treat them with respect and integrity.<br/>We believe in social responsibility and we care about society and the environment.',
            // },
            // {
            //     title: 'Our Mission',
            //     text: 'We believe in innovations that serve people as agents of good. We combine modern technologies with our understanding and experience. As a result, we create stunning products – whether it’s a big corporation’s website or a dating app – and we are rewarded with happy and loyal clients.',
            // },
            // {
            //     title: 'Our Story',
            //     text: 'Our story started in 2019 and as soon as we started out, we reached markets in neighboring countries and later, the US market as well. Today we have offices in London and Citrus Heights, California.<br/>No matter what countries and what clients we’re working with, our principles and values are unyielding.',
            // },
			{
				title: 'Mission statement:',
				text: 'GBMF creates intrinsic value by enabling companies to optimise their digital presence. In developing and designing innovative and creative services through a process driven approach GBMF makes the complex evident.'
			},
			{
				title: 'Vision statement:',
				text: 'GBMF will be the Internationally present, preferred digital services provider for best-in-class organisations who wish to maximise their online, multi-channel presence.'
			},
        ],
    })
}
</script>
