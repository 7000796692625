<template>
    <v-row>
        <v-col cols="12" lg="4">
            <div class="h3 montserrat fontw-700 text-center text-lg-left" v-html="category.title" data-aos="fade-right"></div>
        </v-col>
        <v-col cols="12" lg="8">
            <v-row>
                <v-col cols="12" sm="6" v-for="(item, i) in category.items" :key="i">
                    <service-card :item="item" type="cardlight" class="h-100" data-aos="fade-left"/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import ServiceCard from '@/components/base/ServiceCard.vue'

export default {
    name: 'CategoryServices',
    props: ['category'],
    components: {
        ServiceCard
    }
}
</script>

<style lang="scss">
</style>
