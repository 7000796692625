<template>
    <div class="mb-8 borderbottomop02">
        <p class="h2 montserrat bold mt-md-16 text-center text-md-left" data-aos="fade-right">Technology</p>
        <p class="h4 montserrat text-md-left tehnologydesc text-center text-md-left mb-16" data-aos="fade-right">We are using modern technologies that help us solve any issue you might have.</p>
        <technology-block />
    </div>
</template>

<script>
import TechnologyBlock from '@/components/base/TechnologyBlock.vue'
export default {
    components:{
        TechnologyBlock,
    },
}
</script>

<style lang="scss">
@media all and (min-width: 660px){
    .tehnologydesc{
        width: 75%;
    }
}
@media all and (min-width: 1240px){
    .tehnologydesc{
        width: 40%;
    }
}
</style>
